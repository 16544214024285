<template>
	<div class="teamMembers_block">
		<section class="header-bg" style="">
		</section>
		<div class="se111">
			<div class="se1">
				<p class="title-hr"><strong style="font-size: 28px;font-family: 'Bebas Neue', cursive;">Researchers
					</strong></p>
			</div>
		</div>

		<!-- 团队 -->
		<researchersItem></researchersItem>
	</div>

</template>
<script>
import researchersItem from "@/components/researchersItem/index.vue"

export default {
	name: "teamMembers",
	components: {
		researchersItem
	},
	data() {
		return {

		};
	}
}
</script>

<style lang="scss">
@import "../assets/css/minix.scss";

.teamMembers_block {
	width: 100%;

	.se111 {
		width: 100%;
		margin-top: 40px;
		display: flex;
		justify-content: center;

		.se1 {
			@include responsive-width(1200px);
			height: 20px;
		}
	}
}
</style>